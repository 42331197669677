'use client'

import { Banner } from '@/app/util/banner'
import { giphyUrl } from '@/app/util/env'
import HTLScript from '@/app/util/htl-script'
import fetchAd, {
    bannerAd,
    getKevelGifIdByType,
    getKevelTest,
    getKevelUrlType,
    gridAd,
    KevelAdType,
} from '@/app/util/kevel'
import type { KevelRequestOptions, KevelResponse } from '@/app/util/kevel-types'
import { getCookie, getIdFromSlug } from '@/util/url'
import { getFirstPathname, getPageOptionsBasedOnPath } from 'analytics/src/utils/page'
import { usePathname } from 'next/navigation'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import AdsContext from './ads'

type KevelProps = {
    kevelResponse?: KevelResponse
    kevelLog?: string
    kevelRequestOptions?: Partial<KevelRequestOptions>
}

type Props = {
    children: ReactNode
    banner?: Banner
    htlAds?: string[]
    pageName?: string
    // could be any ad - used as a flag for the search bar
    hasTopLeaderboardAd?: boolean
} & KevelProps

const defaultKevelRequestOptions = { placements: [bannerAd, gridAd] }

function useHtlAds(adUnits: string[]) {
    const isAdUnitEnabled = (unitName: string) => adUnits.includes(unitName)
    const pathname = usePathname()
    useEffect(() => {
        if (adUnits.length > 0) {
            const { pageCategory } = getPageOptionsBasedOnPath(getFirstPathname(pathname))
            const id = pageCategory === 'gif' || pageCategory === 'sticker' ? getIdFromSlug(pathname) : ''
            const htlbid = (window.htlbid = window.htlbid || ({} as HTLBid))
            htlbid.cmd = htlbid.cmd || []
            window.didomiOnReady.push(() => {
                htlbid.cmd.push(() => {
                    const isTesting = giphyUrl === 'https://giphy.com' ? 'no' : 'yes'
                    console.log(`htlbid pageCategory`, pageCategory, 'testing:', isTesting, 'id', id)
                    htlbid.setTargeting('is_testing', isTesting) // Set to "no" for production
                    htlbid.setTargeting('category', pageCategory)
                    htlbid.setTargeting('post_id', id)
                    htlbid.layout()
                })
            })
        }
    }, [adUnits, pathname])
    return isAdUnitEnabled
}

const useKevelManager = ({ kevelResponse, kevelRequestOptions }: KevelProps) => {
    const isKevelAdEnabled = (type: KevelAdType | 'any') => {
        const hasIt = (type: KevelAdType) => {
            return !!getKevelGifIdByType(kevelResponse, type) || !!getKevelUrlType(kevelResponse, type)
        }
        if (type === 'any') {
            return hasIt('banner') || hasIt('grid')
        }
        return hasIt(type)
    }
    const pathname = usePathname()
    const isFetching = useRef('')

    // we refetch kevel data on the client for targeting
    const [kevelClientData, setKevelClientData] = useState<KevelResponse | undefined>()
    useEffect(() => {
        if (kevelResponse && isFetching.current !== pathname) {
            isFetching.current = pathname
            const doFetch = async () => {
                const banner = getCookie('kevel_banner', document.cookie)
                const grid = getCookie('kevel_grid', document.cookie)
                const kevelTest = getKevelTest(banner, grid)
                if (kevelTest) {
                    setKevelClientData(kevelTest)
                } else {
                    setKevelClientData(await fetchAd({ ...defaultKevelRequestOptions, ...(kevelRequestOptions || {}) }))
                }
            }
            doFetch()
        }
    }, [kevelResponse, kevelRequestOptions, pathname])
    return { kevelClientData, isKevelAdEnabled }
}

const AdsContextManager = ({
    children,
    htlAds = [],
    pageName,
    kevelResponse,
    hasTopLeaderboardAd,
    kevelRequestOptions,
    kevelLog,
    banner,
}: Props) => {
    useEffect(() => {
        console.log(`kevelResponse`, kevelResponse || kevelLog)
        console.log(`kevelRequestOptions`, kevelRequestOptions)
    }, [kevelLog, kevelRequestOptions, kevelResponse])
    const isHTLAdEnabled = useHtlAds(htlAds)
    const kevelData = useMemo(() => ({ kevelResponse, kevelRequestOptions }), [kevelRequestOptions, kevelResponse])
    const { kevelClientData, isKevelAdEnabled } = useKevelManager(kevelData)
    const value = useMemo(
        () => ({
            htlAds,
            isHTLAdEnabled,
            pageName,
            kevelClientData,
            banner,
            isKevelAdEnabled,
            hasTopLeaderboardAd,
        }),
        [htlAds, banner, isHTLAdEnabled, isKevelAdEnabled, kevelClientData, pageName, hasTopLeaderboardAd]
    )
    return (
        <AdsContext.Provider value={value}>
            {htlAds.length > 0 && <HTLScript />}
            {children}
        </AdsContext.Provider>
    )
}

export default AdsContextManager
